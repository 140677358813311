import './index.scss';
import $ from 'jquery';
$.fn.webfactoryShowPopup = function(){
	if (document.cookie.indexOf("popupClosed=true") === -1) {
   		 $(this).find('.modal').modal('show');
  	}
}

$.fn.webfactoryClosePopup = function(){ 
	$(this).find('.btn-close').click(function(e){
		document.cookie = "popupClosed=true; path=/";
	})
}

$(function(){
	$('.wp-block-webfactory-popup').each(function(){
		$(this).webfactoryShowPopup();
		$(this).webfactoryClosePopup();
	})
})